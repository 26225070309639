import { ROUTES, THEMES } from "@/utils/constants";
import { clearStorage, getAuthUserType } from "@/utils/helpers/auth";
import { UserType } from "@/utils/types";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const useUserType = () => {
  const navigate = useNavigate();
  const userType = getAuthUserType();

  const theme = useMemo(() => {
    return {
      ...(userType === "parent"
        ? THEMES.parent
        : userType === "organization"
        ? THEMES.org
        : THEMES.child),
      all: { ...THEMES },
    };
  }, [userType]);

  useEffect(() => {
    if (!userType) {
      clearStorage();
      navigate(ROUTES.login);
    }
  }, [navigate, userType]);

  return {
    userType: userType as UserType,
    theme,
  };
};
