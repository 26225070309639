import { extendTheme, type ThemeConfig } from "@chakra-ui/react";
import { textStyles } from "./styles";
import { button } from "./components/button";
import { switchTheme } from "./components/switch";
import { checkboxTheme } from "./components/checkbox";
import { THEMES } from "@/utils/constants";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const chakraTheme = extendTheme({
  colors: {
    ...THEMES,
  },

  styles: {
    global: {
      "html, body": {
        lineHeight: "tall",
      },
      ".thinSB::-webkit-scrollbar": {
        width: "9px",
        height: "7px",
      },
      ".thinSB::-webkit-scrollbar-thumb": {
        backgroundColor: "sec.200",
        // outline: "1px solid #7489AA",
        borderRadius: "1px",
      },
      ".thinSB::-webkit-scrollbar-track": {
        backgroundColor: "sec.50",
      },
      ".thinSB": {
        scrollbarColor: "#EDEEF2 #FAFCFF",
        scrollbarWidth: "thin",
      },
    },
  },
  fonts: {
    heading: `"Buenos Aires", Inter, system-ui, Avenir, Helvetica, Arial, sans-serif`,
    body: `"Roboto", "Buenos Aires", Inter, system-ui, Avenir, Helvetica, Arial, sans-serif`,
  },
  components: {
    Button: button,
    Switch: switchTheme,
    Checkbox: checkboxTheme,
  },
  textStyles,
  config,
});

export default chakraTheme;
