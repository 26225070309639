import { Navigate, useLocation } from "react-router-dom";
import { getAuthToken, setRedirectRoute } from "../../utils/helpers/auth";
import ParentOrgLayout from "@/components/layouts/parentOrg";
import OnboardingLayout from "@/components/layouts/onboarding";

export const PrivateRoute = () => {
  const { pathname } = useLocation();

  if (!getAuthToken()) {
    setRedirectRoute(pathname);
    return <Navigate to="/login" />;
  }

  if (pathname === "/onboarding") {
    return <OnboardingLayout />;
  }

  return <ParentOrgLayout />;
};
