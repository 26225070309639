import { GLOBAL_KEYS } from "../constants";
import Cookie from "js-cookie";
import { UserType } from "../types";

export const clearStorage = () => {
  Cookie.remove(GLOBAL_KEYS.token);
  Cookie.remove(GLOBAL_KEYS.ONBOARDING_COMPLETE);
  Cookie.remove(GLOBAL_KEYS.USER_TYPE);
  Cookie.remove(GLOBAL_KEYS.REDIRECT_ROUTE);
  Cookie.remove(GLOBAL_KEYS.ORG_UUID);
  localStorage.clear();
  sessionStorage.clear();
};

export const getAuthToken = () => Cookie.get(GLOBAL_KEYS.token);

export const setAuthToken = (token: string) =>
  Cookie.set(GLOBAL_KEYS.token, token);

export const getAuthUserType = () => Cookie.get(GLOBAL_KEYS.USER_TYPE);
export const setAuthUserType = (userType: UserType) =>
  Cookie.set(GLOBAL_KEYS.USER_TYPE, userType);

export const getAuthOrgUuid = () => Cookie.get(GLOBAL_KEYS.ORG_UUID);
export const setAuthOrgUuid = (uuid: string) =>
  Cookie.set(GLOBAL_KEYS.ORG_UUID, uuid);
export const removeAuthOrgUuid = () => Cookie.remove(GLOBAL_KEYS.ORG_UUID);

export const getOnboardingComplete = () =>
  Cookie.get(GLOBAL_KEYS.ONBOARDING_COMPLETE);
export const setOnboardingComplete = (isComplete: "true" | "false") =>
  Cookie.set(GLOBAL_KEYS.ONBOARDING_COMPLETE, isComplete);

export const setRedirectRoute = (pathname: string) =>
  sessionStorage.setItem(GLOBAL_KEYS.REDIRECT_ROUTE, pathname);

export const getRedirectRoute = () => {
  const val = sessionStorage.getItem(GLOBAL_KEYS.REDIRECT_ROUTE);

  return val;
};
