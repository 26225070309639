export const textStyles = {
  large: {
    fontSize: { base: "18px", lg: "18px" },
    fontWeight: 500,
    lineHeight: 1.4,
  },
  medium: {
    fontSize: { base: "16px", md: "16px" },
    lineHeight: 1.3,
  },
  normal: {
    fontSize: { base: "14px", md: "14px" },
    lineHeight: 1.4,
  },
  small: {
    fontSize: { base: "13px", md: "12px" },
    lineHeight: 1.4,
  },
  tiny: {
    fontSize: { base: "11px", md: "10px" },
    lineHeight: 1.2,
  },

  header2: {
    fontSize: { base: "18px", lg: "16px" },
    fontWeight: 600,
    lineHeight: { base: "1.4", md: "1.3" },
    mb: "16px",
  },
  header1: {
    fontSize: { base: "26px", lg: "24px" },
    fontWeight: 600,
    lineHeight: { base: "1.4", md: "1.4" },
  },
  header0: {
    fontSize: { base: "22.5px", md: "26.4px" },
    fontWeight: 600,
    lineHeight: "1.3",
  },

  xlarge: {
    fontSize: { base: "30px", md: "34px" },
    fontWeight: 600,
    lineHeight: "1.5",
  },
};
