import axios from "axios";
import { clearStorage, getAuthToken } from "../utils/helpers/auth";
import { API_BASE_URL } from "@/utils/helpers/env";
import { cleanObject } from "@/utils/helpers/functions";

const options = {
  baseURL: API_BASE_URL,
  headers: {
    Accept: "application/json,text/plain,*/*",
    "Content-Type": "application/json",
  },
};

const request = axios.create(options);

request.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // prevent empty|undefined|null values
    if (config.data) {
      // console.log("req data", config.data);
      config.data = cleanObject(config.data);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    try {
      // const errorData = error?.response?.data;

      if (error.response?.status === 403) {
        clearStorage();
        window.location.pathname = "/login";
      }
      // if (
      //   errorData?.message === "Unauthenticated." ||
      //   error.response?.status === 401
      // ) {
      //   clearStorage();
      //   window.location.reload();
      // }

      return Promise.reject(error);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default request;
