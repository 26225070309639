import {
  Box,
  Center,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { Suspense } from "react";
import { Loader } from "../UI/chakra/loaders";
import { Outlet, useLocation } from "react-router-dom";
// import { getOnboardingComplete } from "@/utils/helpers/auth";
import { ScaleIn } from "../animate/scaleIn";
import { useUserType } from "@/hooks/useUserType";
import POrgSidebar from "./sidebar";
import { Menu } from "lucide-react";
import { CustomDrawer } from "../UI/chakra/modals";
import { cn } from "@/lib/utils";
import { ROUTES } from "@/utils/constants";
import { useAuth } from "@/hooks/useAuth";

const ParentOrgLayout = () => {
  // get user here and confirm that onboarding is completed else set onboardingComplete to false and redirect
  const { isLoading: isFetchingUser } = useAuth();

  const { userType, theme } = useUserType();
  // const navigate = useNavigate();
  const { pathname } = useLocation();

  const sideBarModal = useDisclosure();

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsFetchingUser(false);
  //   }, 750);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  // check onboarding
  // useEffect(() => {
  //   if (!isFetchingUser && getOnboardingComplete() !== "true") {
  //     navigate("/onboarding");
  //   }
  // }, [isFetchingUser, navigate]);

  return (
    <>
      <AnimatePresence mode="wait">
        {isFetchingUser ? (
          <ScaleIn key="layoutloading" scale={1}>
            <Center className="section-onboarding" minH={"100vh"}>
              <Loader title={"Grandma Wura Hub is loading..."} />
            </Center>
          </ScaleIn>
        ) : null}

        {!isFetchingUser ? (
          <ScaleIn key="layoutready" scale={1}>
            <Box bg={theme.bg}>
              <Grid
                h={{ lg: "100vh" }}
                overflow={{ lg: "hidden" }}
                templateColumns={{ base: "1fr", lg: "230px 1fr" }}
                gap={0}
                mx={"auto"}
                maxW={"1800px"}
              >
                <GridItem
                  display={{ base: "none", lg: "block" }}
                  height={{ lg: "100vh" }}
                >
                  <Box pt={8} pb={12} h={"100%"}>
                    <POrgSidebar />
                  </Box>
                </GridItem>
                <GridItem
                  height={{ lg: "100vh" }}
                  minHeight={{ base: "100vh", lg: "100%" }}
                  position={"relative"}
                  pb={6}
                  pt={{ base: 3, lg: 6 }}
                  pr={{ base: 2, lg: 6 }}
                  pl={{ base: 2, lg: 4 }}
                >
                  <HStack
                    display={{ base: "flex", lg: "none" }}
                    justifyContent={"space-between"}
                    spacing={6}
                    pb={2}
                    px={2}
                  >
                    <IconButton
                      pt={3}
                      aria-label="menu"
                      title="menu"
                      variant={"ghost"}
                      color={userType === "parent" ? "parent.text" : "org.text"}
                      icon={<Menu size={28} />}
                      onClick={() => {
                        sideBarModal.onOpen();
                      }}
                    />

                    <Image
                      alt="logo"
                      src={"/logo.svg"}
                      width={"100px"}
                      height={"40px"}
                      objectFit={"contain"}
                    />
                  </HStack>

                  <Box
                    height={"100%"}
                    bg={
                      userType === "parent" && pathname === ROUTES.children
                        ? undefined
                        : "white"
                    }
                    border={"4px solid transparent"}
                    borderColor={theme.border}
                    borderRadius={"32px"}
                    overflow={"hidden"}
                  >
                    <Box
                      className={cn("thinSB", {
                        parent_child_bg:
                          userType === "parent" && pathname === ROUTES.children,
                      })}
                      overflowY={"auto"}
                      height={"100%"}
                    >
                      <Box p={{ base: 4, md: 6, lg: 12 }}>
                        <AnimatePresence>
                          <Suspense fallback={<Loader />}>
                            <Outlet />
                          </Suspense>
                        </AnimatePresence>
                      </Box>
                    </Box>
                  </Box>
                </GridItem>
              </Grid>
            </Box>
          </ScaleIn>
        ) : null}
      </AnimatePresence>

      {/* mobile sidebar */}
      <CustomDrawer
        disclosure={sideBarModal}
        size="md"
        drawerContentProps={{
          px: "2px",
          bg: theme.bg,
        }}
        placement="left"
        isMenu
        hideOverlay
      >
        <POrgSidebar onClose={sideBarModal.onClose} isMobile />
      </CustomDrawer>
    </>
  );
};

export default ParentOrgLayout;
