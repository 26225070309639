import { useRecoilValue } from "recoil";
import AuthLayout from "./auth";
import { Outlet } from "react-router-dom";
import { onboardingState } from "@/lib/recoil/atoms/onboarding";
import { Center } from "@chakra-ui/react";

const OnboardingLayout = () => {
  const state = useRecoilValue(onboardingState);
  return (
    <>
      {state?.type ? (
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      ) : (
        <div className="section-onboarding">
          <Center w={"100%"} minH={"100vh"}>
            <Outlet />
          </Center>
        </div>
      )}
    </>
  );
};

export default OnboardingLayout;
