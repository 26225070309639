import { useMutation, useQuery } from "react-query";
import {
  clearStorage,
  removeAuthOrgUuid,
  setAuthOrgUuid,
  setAuthToken,
  setAuthUserType,
} from "../utils/helpers/auth";
import { getUserFn, refreshTokenFn } from "../services/auth";
import { useState } from "react";
import { ChildType, OrganizationType, UserType } from "../utils/types";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/utils/constants";
import { LoginResData } from "@/pages/auth/login";

export interface UserAuthType {
  id: number;
  uuid: string;
  email: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumber: string;
  userType: UserType;
  createdAt: string;
  updatedAt: string;
  isOnboardingCompleted: boolean;
  subscription: null;
  verificationToken: null;
  verificationTokenExpires: null;
  emailVerified: boolean;
  isActive: boolean;
  deletedAt: null;
  controlPin: string;
  parentChildren: ChildType[];
  organizations: OrganizationType[];
  boughtSubscription: {
    id: number;
    uuid: string;
    availableSeats: number;
    usedSeats: number;
    totalSeats: number;
    startDate: string;
    endDate: string;
    isActive: boolean;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    subscriptionPlan: {
      id: number;
      uuid: string;
      name: string;
      currency: string;
      amount: number;
      benefits: string;
      billingPeriod: string;
      createdAt: string;
      updatedAt: string;
      deletedAt: string;
    };
  }[];

  uid?: string;
}

export interface UseAuthProps {
  refreshTokenCallback?: () => void;
}

export const useAuth = (props?: UseAuthProps) => {
  // const userData = useRecoilValue(userDataState);
  // // eslint-disable-next-line
  // // @ts-ignore
  // const [user, setUser] = useState<UserAuthType | null>(userData);
  const [user, setUser] = useState<UserAuthType | null>(null);
  const naviagate = useNavigate();

  const getUserQuery = useQuery(
    ["get_user"],
    () => {
      return getUserFn();
    },
    {
      enabled: !user,
      retry: false,

      refetchInterval: Infinity,
      onSuccess: (data) => {
        setUser(data.data);

        if (!data.data?.isOnboardingCompleted) {
          naviagate(ROUTES.onboarding);
        }

        // fix: this for now takes the index org as default selected organization
        if (data.data?.organizations && data.data?.organizations[0]?.uuid) {
          setAuthOrgUuid(data.data?.organizations[0]?.uuid);
        } else {
          removeAuthOrgUuid();
        }

        // fix: userType "user" creates "oragnisations"
        // chore fix here to set userType as "organisation" if "user"
        if (data?.data?.userType === "user") {
          setAuthUserType("organization");
        } else {
          setAuthUserType(data?.data?.userType);
        }
      },
    }
  );

  const refreshTokenMutation = useMutation(refreshTokenFn, {
    onSuccess: (data) => {
      // console.log(data);
      const res = data?.data as LoginResData;
      if (res) setAuthToken(res.token);
      if (props?.refreshTokenCallback) props.refreshTokenCallback();
    },
  });

  const logout = async () => {
    clearStorage();
    window.location.href = "/login";
  };

  return {
    logout,
    refreshToken: refreshTokenMutation,
    user,
    ...getUserQuery,
  };
};
