import { lazy } from "react";

const LandingPage = lazy(() => import("../../pages/landing"));
const LoginPage = lazy(() => import("../../pages/auth/login"));
const ForgptPasswordPage = lazy(
  () => import("../../pages/auth/forgot-password")
);
const ResetPasswordPage = lazy(() => import("../../pages/auth/reset-password"));
const SignupPage = lazy(() => import("../../pages/auth/signup"));

export const publicRoutes = [
  {
    name: "Landing",
    path: "/",
    component: LandingPage,
  },
  {
    name: "Login",
    path: "/login",
    component: LoginPage,
  },
  {
    name: "Forgot Password",
    path: "/forgot-password",
    component: ForgptPasswordPage,
  },
  {
    name: "Reset Password",
    path: "/reset-password",
    component: ResetPasswordPage,
  },
  {
    name: "Signup",
    path: "/register",
    component: SignupPage,
  },
];
