import { defineStyleConfig, defineStyle } from "@chakra-ui/react";

// const solid = defineStyle({
//   fontSize: "15px",
//   fontWeight: 500,
//   px: 8,
//   // color: "background",
//   borderRadius: "6px",
// });
// const outline = defineStyle({
//   _hover: { bg: "par.50" },
// });

const cancel = defineStyle({
  background: "transparent",
  fontSize: "14px",
  fontWeight: 600,
  color: "gray.700",
  textDecoration: "underline",
  textUnderlineOffset: 2,
  textDecorationThickness: 1,
});

const bubble = defineStyle({
  background: "#F8D79E",
  fontSize: "14px",
  fontWeight: 600,
  color: "#111",
  borderBottom: "4px solid #F2AF3E",
  rounded: "full",
  textUnderlineOffset: 2,
  textDecorationThickness: 1,
  _hover: {
    background: "hsl(38, 87%, 69%)",
    _loading: {
      background: "#F8D79E",
    },
  },
});
const parent = defineStyle({
  background: "parent.main.500",
  fontSize: "14px",
  fontWeight: 600,
  color: "#fff",
  borderBottom: "5px solid #018C55",
  rounded: "full",
  _hover: {
    background: "hsl(156, 99%, 27%)",
    _loading: {
      background: "parent.main.500",
    },
  },
});
const org = defineStyle({
  background: "org.main.500",
  fontSize: "14px",
  fontWeight: 600,
  color: "#fff",
  borderBottom: "5px solid #1483B8",
  rounded: "full",
  _hover: {
    background: "hsl(199, 80%, 44%)",
    _loading: {
      background: "org.main.500",
    },
  },
});

const normal = defineStyle({
  fontSize: { base: "16px", md: "14px" },
  fontWeight: 500,
  px: "6",
  h: { base: "12", md: "10" },
  borderRadius: "6px",
});
const xl = defineStyle({
  fontSize: "16px",
  fontWeight: 600,
  px: "9",
  h: { base: "12", md: "14" },
  borderRadius: "full",
});

export const button = defineStyleConfig({
  baseStyle: {
    rounded: "full",
    fontSize: "14px",
    _active: {
      transform: "scale(0.98)",
    },
    _loading: {
      bg: undefined,
      opacity: 0.8,
    },

    transition: "all 0.15s cubic-bezier(0.645,0.045,0.355,1)",
  },
  variants: { cancel, bubble, parent, org },
  sizes: {
    normal,
    xl,
  },
  defaultProps: {
    colorScheme: "par",
    size: "normal",
  },
});
