import { UserType } from "@/utils/types";
import API from "./index";
import { getErrorMessage } from "@/utils/helpers/functions";

export interface SignupDataType {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  dateOfBirth?: string;
}

export const getUserFn = async () => {
  try {
    const res = await API.get("/user/profile");

    return res.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const signupFn = async (data: SignupDataType) => {
  try {
    const res = await API.post("/auth/signup", data);

    return res.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export interface OnboardDataType {
  userType: UserType;
  numberOfKids?: string | number;
  organizationType?: string;
  organizationName?: string;
  minNumberOfKids?: number;
  maxNumberOfKids?: number;
  controlPin?: string;
}

export const onboardFn = async (data: OnboardDataType) => {
  try {
    const res = await API.post("/auth/onboarding", data);

    return res.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export interface LoginDataType {
  email: string;
  password: string;
}

export const loginFn = async (data: LoginDataType) => {
  try {
    const res = await API.post("/auth/login", data);

    return res.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const refreshTokenFn = async () => {
  try {
    const res = await API.post("/auth/refreshToken");

    return res.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const forgotPasswordFn = async (data: { email: string }) => {
  try {
    const res = await API.post("/auth/forgetPassword", data);

    return res.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const resetPasswordFn = async (data: {
  password: string;
  confrimPassword: string;
  otp: string;
}) => {
  try {
    const res = await API.post("/auth/resetPassword", data);

    return res.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const changePasswordFn = async (data: {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}) => {
  try {
    const res = await API.post("/auth/changePassword", data);

    return res.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const updateProfileFn = async (data: {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  phoneNumber?: string;
  isActive?: boolean;
}) => {
  try {
    const res = await API.patch("/user/profile", data);

    return res.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const updateOrganizationFn = async (data: {
  uuid: string;
  type?: string;
  name?: string;
  email?: string;
}) => {
  try {
    const res = await API.patch(`/organizations/user/${data.uuid || 0}`, data);

    return res.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};
