import { Box, Center, Grid, GridItem, Image } from "@chakra-ui/react";
import { ReactNode } from "react";

export default function AuthLayout({ children }: { children: ReactNode }) {
  return (
    <Box bg={"milk"} w={"100vw"}>
      <Grid
        templateColumns={{ base: "1fr", lg: "2fr 1.8fr" }}
        w={"100%"}
        maxW={"1400px"}
        mx={"auto"}
        h={{ lg: "100vh" }}
        overflow={{ lg: "hidden" }}
      >
        <GridItem height={"100%"} display={{ base: "none", lg: "block" }}>
          <Center h={"100vh"} w={"full"} p={{ base: "24px", lg: "32px" }}>
            <Box
              h={"90%"}
              w={"full"}
              bg={"#C4C4C4"}
              rounded={"24px"}
              overflow={"hidden"}
            >
              <Image
                alt=""
                src={"/auth_bg.webp"}
                height={"100%"}
                objectFit={"cover"}
                objectPosition={"center"}
              />
            </Box>
          </Center>
        </GridItem>

        <GridItem height={"100vh"} overflowY={"auto"} className="thinSB">
          <Center h={"100%"} w={"full"} py={"72px"} pr={{ lg: 16 }}>
            {children}
          </Center>
        </GridItem>
      </Grid>
    </Box>
  );
}
