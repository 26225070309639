import { atom } from "recoil";

interface OnboardingStateType {
  type: "Parent" | "Organization";

  parent?: {
    no_of_students: string;
    names_of_students: string[];
  };
  organization?: {
    type: string;
    name: string;
    no_of_students: string;
  };
}

export const onboardingState = atom<OnboardingStateType | null>({
  key: "onboardingState", // unique ID (with respect to other atoms/selectors)
  default: null,
});
