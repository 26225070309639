import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { PublicRoute } from "./public";
import { publicRoutes } from "./public/router";
import { Loader } from "@/components/UI/chakra/loaders";
import NotFound from "@/components/notFound";
import { PrivateRoute } from "./private";
import { privateRoutes } from "./private/routes";

export default function AppRouter() {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<PublicRoute />}>
          {publicRoutes.map((route) => {
            const Comp = route.component;
            return (
              <Route key={route.name} path={route.path} element={<Comp />} />
            );
          })}
        </Route>

        <Route element={<PrivateRoute />}>
          {privateRoutes.map((route) => {
            const Comp = route.component;
            return (
              <Route key={route.name} path={route.path} element={<Comp />} />
            );
          })}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Suspense>
  );
}
