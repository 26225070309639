import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyleTrack = defineStyle((props) => {
  const { colorScheme: c } = props;

  return {
    bg: `red.500`,
    // borderRadius: "10px",

    px: 1,
    width: "36px",
    _checked: {
      bg: `${c}.400`,
    },
  };
});

const baseStyle = definePartsStyle((props) => ({
  // define the part you're going to style
  container: {
    // bg: "red",
  },
  thumb: {
    bg: "gray.100",
    _checked: {
      transform: "translateX(130%)",
    },
  },
  track: baseStyleTrack(props),
}));

const boxy = definePartsStyle({
  track: {
    borderRadius: "sm",
    p: 1,
  },
});

export const switchTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { boxy },
});
