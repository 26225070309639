import { Center, Flex, Image, Skeleton, Text, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";

export const Loader = ({
  title = "Please wait...",
  height = "90vh",
}: {
  title?: string | ReactNode;
  height?: string;
}) => {
  return (
    <Center h={height}>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        textAlign="center"
      >
        <VStack
          mb={3}
          className="animate-bounceX"
          transform={"scaleX(-1)"}
          style={{ transform: "scaleX(-1)" }}
        >
          <Image
            src={"/bus.png"}
            alt="loading"
            w={"200px"}
            className="animate-bounce"
          />
        </VStack>

        <Text
          fontWeight="600"
          color={"#944000"}
          textStyle={"medium"}
          textAlign={"center"}
          maxW={"280px"}
        >
          {title}
        </Text>
      </Flex>
    </Center>
  );
};

export const PageLoader = ({ squares }: { squares?: boolean }) => {
  return (
    <VStack w={"full"} alignItems={"flex-start"} spacing={squares ? 3 : 2}>
      <Skeleton
        borderRadius={squares ? "8px" : "0px"}
        w={"full"}
        height={"32px"}
      />
      <Skeleton
        borderRadius={squares ? "0px" : "0px"}
        w={squares ? "full" : "80%"}
        height={squares ? "32px" : "18px"}
      />
      <Skeleton
        borderRadius={squares ? "8px" : "0px"}
        w={squares ? "full" : "60%"}
        height={squares ? "32px" : "18px"}
      />
    </VStack>
  );
};
