import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const circular = definePartsStyle({
  control: defineStyle({
    rounded: "full",
  }),
});

// const baseStyle = definePartsStyle({
//   // define the part you're going to style
//   control: {
//     borderWidth: "1px",
//     _selected: {
//       borderColor: "transparent",
//     },
//   },
// });

export const checkboxTheme = defineMultiStyleConfig({
  variants: { circular },
});
