import { ReactNode } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalOverlay,
  ModalOverlayProps,
  ModalProps,
  DrawerContentProps,
  ModalContentProps,
  ModalCloseButtonProps,
} from "@chakra-ui/react";
import { ChakraDisclosureProps } from "@/utils/types";
import { useUserType } from "@/hooks/useUserType";

interface CustomDrawerProps {
  disclosure: ChakraDisclosureProps;
  children: ReactNode;
  placement?: "right" | "left";
  size?: string;
  blockScrollOnMount?: boolean;
  drawerContentProps?: DrawerContentProps;
  hideOverlay?: boolean;
  hideCloseButton?: boolean;
  isMenu?: boolean;
}

interface CustomModalProps {
  disclosure: ChakraDisclosureProps;
  children: ReactNode;
  showCloseButton?: boolean;
  modalCloseButtonProps?: ModalCloseButtonProps;
  footer?: ReactNode;
  header?: string;
  size?: string;
  isCentered?: boolean;
  modalProps?: ModalProps;
  footerProps?: ModalFooterProps;
  modalOverlay?: ModalOverlayProps;
  modalContentProps?: ModalContentProps;
  onCloseComplete?: () => void;
}

export const CustomModal = ({
  disclosure,
  children,
  modalProps,
  modalOverlay,
  header,
  footer,
  footerProps,
  showCloseButton,
  modalCloseButtonProps,
  modalContentProps,
  onCloseComplete,
  isCentered = true,
  size,
}: CustomModalProps) => {
  const { theme } = useUserType();

  return (
    <Modal
      onCloseComplete={onCloseComplete}
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      size={size || "md"}
      // size={size}
      // scrollBehavior="inside"
      isCentered={isCentered}
      {...modalProps}
    >
      <ModalOverlay
        // bg="none"
        // backdropFilter="auto"
        // backdropInvert="80%"
        // backdropBlur="2px"
        {...modalOverlay}
      />
      <ModalContent
        borderRadius={"22px"}
        mx={"12px"}
        minHeight={"180px"}
        overflow={"hidden"}
        {...modalContentProps}
      >
        {header ? (
          <>
            <ModalHeader
              mt={0}
              pt={8}
              pb={1}
              fontSize={"1.6rem"}
              textAlign={"center"}
              fontWeight={700}
              // borderBottom={"2px solid"}
              // borderBottomColor={theme.border}
              // textTransform={"uppercase"}
              mx={6}
            >
              {header}
            </ModalHeader>
            <ModalCloseButton
              color={"text.200"}
              bg={"#1018280A"}
              borderRadius={"full"}
              size={"sm"}
              {...modalCloseButtonProps}
            />
          </>
        ) : null}
        {showCloseButton ? (
          <ModalCloseButton
            mt={2}
            mr={2}
            bg={"gray.100"}
            color={"text.500"}
            border={"1px solid"}
            borderColor={theme.border}
            borderRadius={"6px"}
            fontSize={"12px"}
            w={8}
            h={8}
            fontWeight={700}
            {...modalCloseButtonProps}
          />
        ) : null}
        <ModalBody py={6} px={{ base: 4, sm: 8 }}>
          {children}
        </ModalBody>
        <ModalFooter py={3} {...footerProps}>
          {footer ?? <></>}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export function CustomDrawer({
  disclosure,
  children,
  drawerContentProps,
  hideOverlay,
  hideCloseButton,
  isMenu,
  size = "xs",
  placement = "right",
  blockScrollOnMount = true,
}: CustomDrawerProps) {
  return (
    <Drawer
      size={size}
      isOpen={disclosure.isOpen}
      placement={placement}
      blockScrollOnMount={blockScrollOnMount}
      onClose={disclosure.onClose}
    >
      {!hideOverlay ? <DrawerOverlay /> : null}
      <DrawerContent bg={"white"}>
        {!hideCloseButton ? (
          <DrawerCloseButton
            mt={5}
            mx={5}
            bg={isMenu ? "background" : undefined}
            zIndex={100}
          />
        ) : null}

        <DrawerBody
          mt={hideCloseButton ? 0 : isMenu ? 0 : 14}
          p={hideCloseButton ? 0 : undefined}
          px={hideCloseButton ? 0 : "12px"}
          pb={hideCloseButton ? 0 : "2px"}
          className="thinSB"
          {...drawerContentProps}
        >
          {children}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
