import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.tsx";
import "./index.css";

import * as Sentry from "@sentry/react";

import "./font/BuenosAires-Black.eot";
import "./font/BuenosAires-Black.ttf";
import "./font/BuenosAires-Black.woff";
import "./font/BuenosAires-Black.woff2";
import "./font/BuenosAires-BlackItalic.eot";
import "./font/BuenosAires-BlackItalic.ttf";
import "./font/BuenosAires-BlackItalic.woff";
import "./font/BuenosAires-BlackItalic.woff2";
import "./font/BuenosAires-Bold.eot";
import "./font/BuenosAires-Bold.ttf";
import "./font/BuenosAires-Bold.woff";
import "./font/BuenosAires-Bold.woff2";
import "./font/BuenosAires-BoldItalic.eot";
import "./font/BuenosAires-BoldItalic.ttf";
import "./font/BuenosAires-BoldItalic.woff";
import "./font/BuenosAires-BoldItalic.woff2";
import "./font/BuenosAires-Book.eot";
import "./font/BuenosAires-Book.ttf";
import "./font/BuenosAires-Book.woff";
import "./font/BuenosAires-Book.woff2";
import "./font/BuenosAires-Light.eot";
import "./font/BuenosAires-Light.ttf";
import "./font/BuenosAires-Light.woff";
import "./font/BuenosAires-Light.woff2";
import "./font/BuenosAires-BookItalic.eot";
import "./font/BuenosAires-BookItalic.ttf";
import "./font/BuenosAires-BookItalic.woff";
import "./font/BuenosAires-BookItalic.woff2";
import "./font/BuenosAires-LightItalic.eot";
import "./font/BuenosAires-LightItalic.ttf";
import "./font/BuenosAires-LightItalic.woff";
import "./font/BuenosAires-LightItalic.woff2";
import "./font/BuenosAires-Regular.eot";
import "./font/BuenosAires-Regular.ttf";
import "./font/BuenosAires-Regular.woff";
import "./font/BuenosAires-Regular.woff2";
import "./font/BuenosAires-RegularItalic.eot";
import "./font/BuenosAires-RegularItalic.ttf";
import "./font/BuenosAires-RegularItalic.woff";
import "./font/BuenosAires-RegularItalic.woff2";
import "./font/BuenosAires-SemiBold.eot";
import "./font/BuenosAires-SemiBold.ttf";
import "./font/BuenosAires-SemiBold.woff";
import "./font/BuenosAires-SemiBold.woff2";
import "./font/BuenosAires-SemiBoldItalic.eot";
import "./font/BuenosAires-SemiBoldItalic.ttf";
import "./font/BuenosAires-SemiBoldItalic.woff";
import "./font/BuenosAires-SemiBoldItalic.woff2";
import "./font/BuenosAires-Thin.eot";
import "./font/BuenosAires-Thin.ttf";
import "./font/BuenosAires-Thin.woff";
import "./font/BuenosAires-Thin.woff2";
import "./font/BuenosAires-ThinItalic.woff2";
import "./font/BuenosAires-ThinItalic.woff";
import "./font/BuenosAires-ThinItalic.ttf";
import "./font/BuenosAires-ThinItalic.eot";

if (import.meta.env.MODE === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

Sentry.init({
  dsn: "https://a471c6365c471c64517071c33318687d@o4508216804311040.ingest.de.sentry.io/4508229953912912",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <App />
  </StrictMode>
);
